<template>
  <div class="regist-container">
    <LoginHeader />
    <div class="page-wrap">
      <div class="page-content-wrap">
        <div class="regist-form">
          <div class="title">欢迎注册！</div>
          <div class="form-item">
            <div class="label"><span>*</span>用户名：</div>
            <div class="form-data-box">
              <input v-model="params.name" type="text" placeholder="请输入用户名">
            </div>
          </div>
          <div class="form-item">
            <div class="label"><span>*</span>手机号码：</div>
            <div class="form-data-box">
              <input v-model="params.mobile" type="text" placeholder="请输入手机号码">
            </div>
          </div>
          <div class="form-item">
            <div class="label"><span>*</span>密码：</div>
            <div class="form-data-box">
              <input v-model="params.password" type="password" placeholder="请输入6-20位的密码">
            </div>
          </div>
          <div class="form-item">
            <div class="label"><span>*</span>确认密码：</div>
            <div class="form-data-box">
              <input v-model="params.password_confirmation" type="password" placeholder="请再次输入密码">
            </div>
          </div>
          <div class="form-item">
            <div class="label"><span>*</span>企业名称：</div>
            <div class="form-data-box">
              <input v-model="params.company_name" type="text" placeholder="请输入企业名称">
            </div>
          </div>
          <div class="form-item">
            <div class="label"><span>*</span>验证码：</div>
            <div class="form-data-box">
              <input v-model="params.code" type="text" placeholder="请输入验证码">
              <div @click="getCodeHandle" class="get-code-btn">{{ codeText }}</div>
            </div>
          </div>
          <div class="tips">已有账户？<router-link to="/login" style="color: #D9262C;">请登录</router-link></div>
          <div @click="registHandle" class="regist-btn" style="background-color: #D9262C;">注册</div>
          <div class="agree">
            <span @click="isAgree = !isAgree" v-if="isAgree" class="icon iconfont icon-checkbox-xuanzhong"></span>
            <span @click="isAgree = !isAgree" v-else class="icon iconfont icon-checkbox-weixuan"></span>
            我已充分阅读、理解并同意
            <span @click="showAgreement" class="agree-text" style="color: #D9262C;">《鹏隆工品网站客户注册须知》</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginHeader from '@/components/header/header-login.vue'
import md5 from 'js-md5'
export default {
  components: {
    LoginHeader
  },
  data() {
    return {
      isAgree: false,
      params: {
        name: '',
        mobile: '',
        password: '',
        password_confirmation: '',
        company_name: '',
        code: ''
      },
      key: process.env.VUE_APP_SIGNKEY,
      codeText: '获取验证码',
      timer: null,
      num: 60
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async showAgreement() {
      const _this = this
      const res = await this.$http.get('/api/acticle/207', {})
      this.$alert('<div style="overflow-y: auto; height: 80vh;">' + res.content + '</div>', res.name, {
        dangerouslyUseHTMLString: true,
        customClass: 'agreement-modal',
        confirmButtonText: '已同意本协议',
        callback: function (action, instance) {
          console.log(action, instance)
          if (action === 'confirm') {
            _this.isAgree = true
          }
        }
      })
    },
    // 注册
    async registHandle() {
      if (!this.params.name) {
        this.$message.error({
          message: '用户名不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.checktel(this.params.mobile)) {
        return false
      }
      if (!this.params.password) {
        this.$message.error({

          message: '密码不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.password_confirmation) {
        this.$message.error({

          message: '请再次输入密码',
          duration: 1500
        })
        return false
      }
      if (this.params.password !== this.params.password_confirmation) {
        this.$message.error({

          message: '两次输入的密码不一致',
          duration: 1500
        })
        return false
      }
      if (!this.params.company_name) {
        this.$message.error({

          message: '企业名称不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.code) {
        this.$message.error({

          message: '验证码不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.isAgree) {
        this.$message.error({
          message: '请阅读并同意《鹏隆工品网站客户注册须知》',
          duration: 1500
        })
        return false
      }
      const res = await this.$http.post('/api/register', this.params)
      localStorage.setItem('token', res.token)
      this.$store.dispatch('setUserInfo')
      this.$router.push({ path: '/' })
    },
    // 获取验证码
    async getCodeHandle() {
      if (this.num != 60) {
        return false
      }
      if (!this.checktel(this.params.mobile)) {
        return false
      }
      const timestamp = parseInt(new Date().getTime() / 1000).toString()
      const params = {
        mobile: this.params.mobile,
        sign: md5(md5(timestamp) + md5(this.params.mobile) + md5(this.key)),
        timestamp: Number(timestamp)
      }
      const _this = this
      this.timer = setInterval(() => {
        _this.num = _this.num - 1
        if (_this.num <= 0) {
          clearInterval(_this.timer)
          _this.codeText = '获取验证码'
          _this.num = 60
        } else {
          _this.codeText = _this.num + 's后重试'

        }
      }, 1000)
      console.log(params)
      await this.$http.post('/common/sendsms', params)
    },
    // 手机号校验
    checktel(val) {
      if (val == '') {
        this.$message.error('手机号不能为空')
        return false
      }
      let myreg = /^[1][2,3,4,5,7,6,8,9][0-9]{9}$/
      if (!myreg.test(val)) {
        this.$message.error('手机号格式不正确')
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss">
.agreement-modal {
  width: 700px;
}
</style>
<style lang="scss" scoped>
.regist-container {
  .page-wrap {
    background-image: url('../../assets/images/common/regist_bg.png');
    background-size: cover;
    background-position: center center;

    .page-content-wrap {
      padding: 30px 0;

      .regist-form {
        background-color: #ffffff;
        margin: 0 auto;
        width: 550px;
        border-radius: 5px;
        padding: 40px 30px;
        font-size: 16px;

        .title {
          text-align: center;
          color: #D9262C;
          font-size: 24px;
          line-height: 1.1;
          margin-bottom: 10px;
        }

        .form-item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .label {
            text-align: right;
            min-width: 87px;

            span {
              color: red;
            }
          }

          .form-data-box {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            flex: 1;

            input {
              flex: 1;
              border: 0 none;
              outline: none;
              line-height: 60px;
              font-size: 16px;
            }

            .get-code-btn {
              color: #D9262C;
              cursor: pointer;
            }
          }
        }

        .tips {
          color: #999999;
          text-align: center;
          margin-top: 20px;

          a {
            color: #3385FF;
            text-decoration: none;
          }
        }

        .regist-btn {
          width: 100%;
          height: 45px;
          background-color: #D9262C;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          margin-top: 30px;
        }

        .agree {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          color: #999999;

          .iconfont {
            font-size: 20px;
            // transform: translateY(1px);
            margin-right: 2px;

            &.icon-checkbox-xuanzhong {
              color: #D9262C;
            }
          }

          span {
            cursor: pointer;
          }

          .agree-text {
            color: #3385FF;
          }
        }
      }
    }
  }
}
</style>
